import axios from "axios"
const initialState = () => {
  return {
    allMarketers: [],
    singleMarketerData: [],
    managerWiseMarketers: [],
  }
}

const state = initialState()
const getters = {
  getAllMarketers: (state) => state.allMarketers,
  getSingleMarketerData: (state) => state.singleMarketerData,
  getManagerWiseMarketers: (state) => state.managerWiseMarketers,
}

const mutations = {
  setAllMarketers(state, data) {
    state.allMarketers = data
  },
  setSingleMarketerData(state, data) {
    state.singleMarketerData = data
  },
  setManagerWiseMarketers(state, data) {
    state.managerWiseMarketers = data
  },
  reset: (state) => {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

const actions = {
  fetchAllMarketers: async ({ commit, dispatch }, params) => {
    try {
      const response = await axios.post(
        "https://mgh-api-analytics.labsquire.com/v1.0/marketers-stats/combined",
        params
      )
      commit("setAllMarketers", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchMarketerData: async ({ commit, dispatch }, params) => {
    try {
      const response = await axios.post(
        "https://mgh-api-analytics.labsquire.com/v1.0/marketers-stats/individual",
        params
      )
      commit("setSingleMarketerData", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchManagerWiseMarketersData: async ({ commit, dispatch }, managerId) => {
    try {
      const response = await axios.get(
        `https://mgh-api-analytics.labsquire.com/v1.0/lis/manager/${managerId}`
      )
      commit("setManagerWiseMarketers", response.data.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
