import axios from "@/config/axios";

// initial state
const initialState = () => {
  return {
    user: null,
    authenticationStatus: null,
    authenticationDetails: {},
    passwordUpdateStatus: null,
    generalErrorMessage: null,
    forgotPasswordStatus: {},
    forgotPasswordVerificationStatus: null,
    userRequestedActionTime: null,
  };
};

const state = initialState();

const getters = {
  getAuthenticatedUser: (state) => state.user,
  getAuthenticationStatus: (state) => state.authenticationStatus,
  getAuthenticationDetails: (state) => state.authenticationDetails,
  getGeneralErrorMessage: (state) => state.generalErrorMessage,
  getPasswordUpdateStatus: (state) => state.passwordUpdateStatus,
  getForgotPasswordStatus: (state) => state.forgotPasswordStatus,
  getForgotPasswordVerificationStatus: (state) =>
    state.forgotPasswordVerificationStatus,
  getUserRequestedActionTime: (state) => state.userRequestedActionTime,
};

const mutations = {
  setAuthenticatedUser: (state, user) => {
    state.user = user;
  },
  setAuthenticationStatus: (state, status) => {
    state.authenticationStatus = status;
  },
  setAuthenticationDetails: (state, tokens) => {
    state.authenticationDetails = tokens;
  },
  setPasswordUpdateStatus: (state, message) => {
    state.passwordUpdateStatus = message;
  },
  setForgotPasswordStatus: (state, data) => {
    state.forgotPasswordStatus = data;
  },
  setForgotPasswordVerificationStatus: (state, status) => {
    state.forgotPasswordVerificationStatus = status;
  },
  setUserRequestedActionTime: (state, time) => {
    state.userRequestedActionTime = time;
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  signOut: async ({ commit, dispatch }) => {
    dispatch("errors/clear", [], { root: true });
    commit("auth/reset", null, { root: true });
    commit("auth/resetProfileData", null, { root: true });
  },

  signin: async ({ commit, dispatch }, params) => {
    try {
      dispatch("errors/clear", [], { root: true });

      commit("auth/reset", null, { root: true });
      let response = await axios.post("/signin", params);
      let userDetails = response.data.user_details;
      let tokens = {
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
      };
      commit("auth/setAuthenticatedUser", userDetails, { root: true });

      commit("auth/setAuthenticationDetails", tokens, { root: true });

      commit("auth/setAuthenticationStatus", true, { root: true });
    } catch (error) {
      console.log(error);
      commit("auth/setAuthenticatedUser", null, { root: true });
      commit("auth/setAuthenticationDetails", null, { root: true });
      commit("auth/setAuthenticationStatus", false, { root: true });
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true });
      }
    }
  },

  reset: function (context) {
    context.dispatch("errors/clear", [], { root: true });
    context.commit("auth/reset", null, { root: true });
  },
  updatePassword: async ({ commit, dispatch }, params) => {
    try {
      dispatch("errors/clear", [], { root: true });
      commit("auth/setPasswordUpdateStatus", null, {
        root: true,
      });
      let response = await axios.patch("/password/update", params);
      commit("auth/setPasswordUpdateStatus", response.data, {
        root: true,
      });
    } catch (error) {
      commit("auth/setPasswordUpdateStatus", null, {
        root: true,
      });
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  forgotPassword: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setForgotPasswordStatus", null);
      let response = await axios.post("/forgot-password", params);
      commit("setForgotPasswordStatus", response.data);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
      console.log(err);
    }
  },
  forgotPasswordVerify: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setForgotPasswordVerificationStatus", null);
      let response = await axios.post("/forgot-password/verify", params);
      commit("setForgotPasswordVerificationStatus", response.data);
    } catch (err) {
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      });
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
