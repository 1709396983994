import AuthMiddleware from "@/middleware/auth";

const AllBulkAccession = () =>
  import(
    /* webpackChunkName: "bulkaccession" */ "@/views/bulkAccessions/AllBulkAccession"
  );
const FailedBulkAccession = () =>
  import(
    /* webpackChunkName: "bulkaccession" */ "@/views/bulkAccessions/FailedBulkAccession"
  );
const CompleteBulkAccession = () =>
  import(
    /* webpackChunkName: "bulkaccession" */ "@/views/bulkAccessions/CompleteBulkAccession"
  );

const BulkAccessionsInfo = () =>
  import(
    /* webpackChunkName: "bulkaccession" */ "@/views/bulkAccessions/BulkAccessionsView"
  );

const BulkAccessionsRouter = [
  {
    path: "/bulk-accessions",
    name: "allBulkAccession",
    component: AllBulkAccession,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "BULK ACCESSIONS",
    },
  },
  {
    path: "/bulk-accessions/failed",
    name: "failedBulkAccession",
    component: FailedBulkAccession,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "BULK ACCESSIONS",
    },
  },
  {
    path: "/bulk-accessions/complete",
    name: "completeBulkAccession",
    component: CompleteBulkAccession,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "BULK ACCESSIONS",
    },
  },
  {
    path: "/bulk-accessions/:bulk_accession_id",
    name: "BulkAccessionsInfo",
    component: BulkAccessionsInfo,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "BULK ACCESSIONS",
    },
  },
];

export { BulkAccessionsRouter };
