import axios from "axios"
// import appConfig from "@/config/app";
import store from "@/store"
import router from "@/router"
import moment from "moment"

let apiUrl = ""

if (process.env.NODE_ENV == "production") {
  apiUrl = process.env.VUE_APP_PROD_API_URL || process.env.VUE_APP_LOCAL_API_URL
} else if (process.env.NODE_ENV == "staging") {
  apiUrl = process.env.VUE_APP_STAG_API_URL || process.env.VUE_APP_LOCAL_API_URL
} else {
  apiUrl = process.env.VUE_APP_DEV_API_URL || process.env.VUE_APP_LOCAL_API_URL
}

const $axios = axios.create({
  baseURL: apiUrl,
  timeout: 1000000,
})

$axios.interceptors.request.use(
  (config) => {
    // for auto logout
    const userPreviousRequestTime =
      store.getters["auth/getUserRequestedActionTime"]

    const currentReqTime = moment().format("YYYY-MM-DD HH:mm:ss")

    const isSessionLoggedUser = store.getters["session/getIsSessionLoggeduser"]
    let idleTime = -1
    const MAX_IDLE_TIME = 30
    if (userPreviousRequestTime && isSessionLoggedUser) {
      idleTime = moment(currentReqTime).diff(
        moment(userPreviousRequestTime),
        "minutes"
      )

      store.commit("auth/setUserRequestedActionTime", currentReqTime)
    } else if (isSessionLoggedUser) {
      idleTime = 0
      store.commit("auth/setUserRequestedActionTime", currentReqTime)
    }

    let authDetails = store.getters["auth/getAuthenticationDetails"]
    let authPatient = store.getters["patientPortal/getOrderCreationData"]
    let clientDetails =
      store.getters["clientPendingOrders/getClientLoginAccessToken"]
    if (authDetails && authDetails["access_token"]) {
      config.headers.common["Authorization"] = authDetails["access_token"]
    } else if (clientDetails) {
      config.headers.common["Authorization"] = clientDetails
    } else {
      config.headers.common["Authorization"] = ""
    }

    if (authPatient && authPatient["phone"]) {
      config.headers.common["Phone"] = authPatient["phone"]
    } else {
      config.headers.common["Phone"] = ""
    }

    if (idleTime) {
      config.headers.common["user-idle-time-in-min"] = idleTime
    } else {
      config.headers.common["user-idle-time-in-min"] = ""
    }

    if (idleTime >= MAX_IDLE_TIME) {
      alert("You have been logged out due to inactivity.")

      // Clear session details and log out user
      resetStore()

      router.push("/signin")
    }
    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

$axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    let originalRequest = error.config
    let errorStatusCode = error.response.status

    // && !originalRequest._retry
    if (errorStatusCode === 401) {
      originalRequest._retry = true
      if (error.message == "jwt expired") {
        alert("Session Expired !")
      }
      resetStore()
      if (error.config.url !== "/signin") {
        router.push("/signin")
      }
    }
    return Promise.reject(error)
  }
)

const resetStore = () => {
  store.dispatch("globalReset")
}
export default $axios
