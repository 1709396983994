import axios from "@/config/axios"

const initiateState = () => {
  return {
    allPhlebioOrders: [],
    sendOrdersToPhlebio: false,
    singlePhlebioDetails: null,
    updatePhlebioStatus: false,
  }
}
const state = initiateState()
const getters = {
  getAllPhlebioOrders: (state) => state.allPhlebioOrders,
  getSendOrdersToPhlebioStatus: (state) => state.sendOrdersToPhlebio,
  getSinglePhlebioDetails: (state) => state.singlePhlebioDetails,
  getUpdatePhlebioStatus: (state) => state.updatePhlebioStatus,
}
const mutations = {
  setAllPhlebioOrders(state, data) {
    state.allPhlebioOrders = data
  },
  setSendOrdersToPhlebio(state, data) {
    state.sendOrdersToPhlebio = data
  },
  setSinglePhlebioDetails(state, data) {
    state.singlePhlebioDetails = data
  },
  setUpdatePhlebioStatus(state, data) {
    state.updatePhlebioStatus = data
  },
  reset: (state) => {
    const initial = initiateState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}
const actions = {
  fetchPhlebioOrders: async ({ commit, dispatch }, params) => {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_PHLEBIO_URL}/phlebio-orders`,
        { params }
      )
      commit("setAllPhlebioOrders", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  sendOrdersToPhlebio: async ({ commit, dispatch }, params) => {
    try {
      let phlebo_id = params.emr_id
      delete params.emr_id
      commit("setSendOrdersToPhlebio", false)
      await axios.post(
        `${process.env.VUE_APP_PHLEBIO_URL}/phlebio-orders/emr-orders/${phlebo_id}/send`,
        {
          to_address_type: params.to_address,
          address_value: params.address_value,
        }
      )
      commit("setSendOrdersToPhlebio", true)
    } catch (error) {
      commit("setSendOrdersToPhlebio", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  phlebioDetails: async ({ commit, dispatch }, params) => {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_PHLEBIO_URL}/phlebio-orders/${params.phlebio_id}`
      )
      commit("setSinglePhlebioDetails", response.data.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  updatePhlebio: async ({ commit, dispatch }, params) => {
    try {
      let phlebio_id = params.phlebio_id
      delete params.phlebio_id
      commit("setUpdatePhlebioStatus", false)
      await axios.put(
        `${process.env.VUE_APP_PHLEBIO_URL}/phlebio-orders/${phlebio_id}`,
        params
      )
      commit("setUpdatePhlebioStatus", true)
    } catch (error) {
      commit("setUpdatePhlebioStatus", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
}
export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions,
}
