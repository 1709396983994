import axios from "@/config/axios"

const initialState = () => {
  return {
    resultFiles: [],
    pharamacyReportSentStatus: false,
    pharmacyOrderSentStatus: false,
    reportSyncStatus: false,
    toxicologyAccessionIds: [],
    sampleNames: [],
    sendDiscreteResult: false,
    discreteResultsToCSV: [],
    downloadDiscreteResults: [],
    discreteResultsDetails: [],
    removeDiscreteResults: false,
  }
}

const state = initialState()

const getters = {
  getAllResultFiles: (state) => state.resultFiles,
  getPharamcyReportSentStatus: (state) => state.pharamacyReportSentStatus,
  getPharmacyOrderSentStatus: (state) => state.pharmacyOrderSentStatus,
  getPharmacySyncStatus: (state) => state.reportSyncStatus,
  getToxicologyAccessionIds: (state) => state.toxicologyAccessionIds,
  getSampleNames: (state) => state.sampleNames,
  getDiscreteResultSendStatus: (state) => state.sendDiscreteResult,
  getDiscreteResultsToCSVData: (state) => state.discreteResultsToCSV,
  getdownloadDiscreteResultsStatus: (state) => state.downloadDiscreteResults,
  getDiscreteResultsDetails: (state) => state.discreteResultsDetails,
  getRemoveDiscreteResultsStatus: (state) => state.removeDiscreteResults,
}

const mutations = {
  setAllResultFiles(state, resultFiles) {
    state.resultFiles = resultFiles
  },
  setPharamacySentStatus(state, status) {
    state.pharamacyReportSentStatus = status
  },
  setPharamacyOrderSentStatus(state, status) {
    state.pharmacyOrderSentStatus = status
  },
  setPharamacyReportSyncStatus(state, status) {
    state.reportSyncStatus = status
  },
  setToxicologyAccessionIds(state, data) {
    state.toxicologyAccessionIds = data
  },
  setSampleNames(state, data) {
    state.sampleNames = data
  },
  setSendDiscreteResult(state, data) {
    state.sendDiscreteResult = data
  },
  setDiscreteResultsToCSV(state, data) {
    state.discreteResultsToCSV = data
  },
  setDownloadDiscreteResults(state, data) {
    state.downloadDiscreteResults = data
  },
  setDiscreteResultsDetails(state, data) {
    state.discreteResultsDetails = data
  },
  setRemoveDiscreteResults(state, data) {
    state.removeDiscreteResults = data
  },
  reset: (state) => {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}

const actions = {
  fetchAllResultFiles: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("/result-files-covid", { params })

      commit("setAllResultFiles", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  setPharamcyReportStatus: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })
      commit("setPharamacySentStatus", false)
      await axios.post(
        `/result-files-covid/${params.id}/set-pharmacy-report-status`
      )

      commit("setPharamacySentStatus", true)
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  sendPharamacyOrder: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })
      commit("setPharamacyOrderSentStatus", false)
      await axios.post(
        `/result-files-covid/${params.id}/pharmacy-orders/${params.report_id}/send`
      )

      commit("setPharamacyOrderSentStatus", true)
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  syncPharamacyReports: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })
      commit("setPharamacyReportSyncStatus", false)
      await axios.post(
        `/result-files-covid/${params.id}/pharmacy-orders/${params.report_id}/sync`
      )

      commit("setPharamacyReportSyncStatus", true)
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },

  async fetchSampleNames({ commit, dispatch }, params) {
    try {
      let resultFileId = params.result_file_id
      delete params.result_file_id

      params.params = { get_all: true }

      await dispatch("errors/clear", [], { root: true })
      const response = await axios.get(
        `/result-files-covid/${resultFileId}/sample-names`,
        params
      )
      commit("setSampleNames", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },

  async fetchToxicologyReports({ commit, dispatch }, params) {
    try {
      await dispatch("errors/clear", [], { root: true })
      const response = await axios.post(
        `/result-files-covid/${params.id}/generate-report`,
        params.data
      )
      commit("setToxicologyAccessionIds", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  async addDiscreteResults({ commit, dispatch }, params) {
    try {
      await dispatch("errors/clear", [], { root: true })
      commit("setSendDiscreteResult", false)
      commit("setDiscreteResultsToCSV", [])
      let response = await axios.post("/sendout-discrete-results", {
        case_id: params.case_id,
        test_report_id: params.test_report_id,
        discrete_results: params.discrete_results,
      })
      commit("setSendDiscreteResult", true)
      commit("setDiscreteResultsToCSV", response.data.data)
    } catch (error) {
      commit("setSendDiscreteResult", false)
      commit("setDiscreteResultsToCSV", [])
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  downloadDiscreteData: async ({ commit }, params) => {
    try {
      commit("setDownloadDiscreteResults", [])
      const response = await axios.get(
        `${process.env.VUE_APP_SERVICES_URL}/result-files/${params.result_file_id}/sample-names/${params.report_id}/${params.test_results_id}/download-as-csv`,
        {
          headers: {
            Authorization:
              "75UXVPVp3OPkrxm-kpTD5yjkht9zynzqwvaWEepurblqnN6CEAqprguz_FAAYIBZ",
          },
        }
      )
      commit("setDownloadDiscreteResults", response.data)
    } catch (error) {
      commit("setDownloadDiscreteResults", [])
      console.log(error)
    }
  },
  fetchDiscreteResults: async ({ commit, dispatch }, params) => {
    try {
      let discreteId = params.discrete_id
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get(
        `/sendout-discrete-results/${discreteId}`
      )

      commit("setDiscreteResultsDetails", response.data.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  removeDiscreteResults: async ({ commit, dispatch }, params) => {
    try {
      let discreteId = params.discrete_id
      await dispatch("errors/clear", [], { root: true })
      commit("setRemoveDiscreteResults", false)

      await axios.delete(`/sendout-discrete-results/${discreteId}`)

      commit("setRemoveDiscreteResults", true)
    } catch (error) {
      commit("setRemoveDiscreteResults", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
