import * as PrivateAxios from "axios"
import moment from "moment-timezone"
import axios from "@/config/axios"

const initialState = () => {
  return {
    allOrders: [],
    caseInformation: {},
    caseInformationValidationErrors: {},
    caseInformationValidationErrorsCode: {},
    caseUpdateInformationValidationErrorsCode: {},
    accessionCreationValidationErrors: {},
    caseInformationSaveStatus: false,
    caseInformationErr: null,
    caseDeleteStatus: false,
    caseDeleteError: null,
    attachmentUploadURL: null,
    attachmentFileId: null,
    attachmentUploadStatusUpdated: false,
    attachmentUploadStatus: false,

    attachmentDeleteStatus: false,

    accessionCreateStatus: false,
    allOrderErrors: null,
    restoreOrderStatus: false,
    restoreOrderError: false,
    // Global search
    globalSearchOrders: [],

    showMedication: false,

    deleteOrderBillingInfoStatus: false,
    downloadOrderDataStatus: false,
    accessionCreationValidationErrorCode: {},
    report: null,
    requisitionDownloadStatus: false,
    multipleBarcodeSources: [],
    multipleBarcodeStatus: false,
    validationOrders: [],
    updateValidationStatus: false,
    bulkValidations: false,
    scanOrderLogs: [],
    getOrderDetails: null,
    createAccessionDetails: null,
    updateOrderStatus: null,
    orderCounts: {}
  }
}

const state = initialState()

const getters = {
  getCaseInformationValidationErrors: (state) =>
    state.caseInformationValidationErrors,
  getCaseInformationSaveStatus: (state) => state.caseInformationSaveStatus,
  getAllOrders: (state) => state.allOrders,
  getAllOrderErrors: (state) => state.allOrderErrors,

  getCaseInformation: (state) => state.caseInformation,

  getCaseDeleteStatus: (state) => state.caseDeleteStatus,
  getCaseDeleteError: (state) => state.caseDeleteError,

  getFileUploadURLForAttachments: (state) => state.attachmentUploadURL,
  getFileUploadRefIdForAttachment: (state) => state.attachmentFileId,
  getAttachmentUploadStatusUpdated: (state) =>
    state.attachmentUploadStatusUpdated,
  getAttachmentUploadStatus: (state) => state.attachmentUploadStatus,
  getAttachmentDeleteStatus: (state) => state.attachmentDeleteStatus,
  getAccessionCreateStatus: (state) => state.accessionCreateStatus,
  getAccessionCreationValidationErrors: (state) =>
    state.accessionCreationValidationErrors,
  getAccessionCreationValidationErrorCode: (state) =>
    state.accessionCreationValidationErrorCode,
  getRestoreOrderStatus: (state) => state.restoreOrderStatus,
  getRestoreOrderError: (state) => state.restoreOrderError,

  getGlobalSearchOrders: (state) => state.globalSearchOrders,

  getShowMedication: (state) => state.showMedication,

  getDeleteOrderBillingInfoStatus: (state) =>
    state.deleteOrderBillingInfoStatus,
  getDownloadOrderDataStatus: (state) => state.downloadOrderDataStatus,

  getCaseInformationValidationErrorsCode: (state) =>
    state.caseInformationValidationErrorsCode,
  getCaseUpdateInformationValidationErrorsCode: (state) =>
    state.caseUpdateInformationValidationErrorsCode,
  getReport: (state) => state.report,
  getDownloadReportStatus: (state) => state.requisitionDownloadStatus,
  getMultipleBarcodeSources: (state) => state.multipleBarcodeSources,
  getMultipleBarcodeStatus: (state) => state.multipleBarcodeStatus,
  getValidationOrders: (state) => state.validationOrders,
  getUpdateValidationStatus: (state) => state.updateValidationStatus,
  getBulkValidations: (state) => state.bulkValidations,
  getScanOrderLogs: (state) => state.scanOrderLogs,
  getScanOrderDetails: (state) => state.getOrderDetails,
  getCreateAccessionDetails: (state) => state.createAccessionDetails,
  getUpdateOrderStatus: (state) => state.updateOrderStatus,
  getOrderCounts: (state) => state.orderCounts
}

const mutations = {
  setCaseInformationValidationErrors(state, data) {
    state.caseInformationValidationErrors = data
  },

  setCaseInformationSaveStatus(state, data) {
    state.caseInformationSaveStatus = data
  },

  setAllOrders(state, data) {
    state.allOrders = data
  },
  setAllOrderErrors(state, data) {
    state.allOrderErrors = data
  },

  setCaseInformationError(state, data) {
    state.caseInformationErr = data
  },
  setCaseInformation(state, data) {
    state.caseInformation = data
  },

  setCaseDeleteError(state, data) {
    state.caseDeleteError = data
  },
  setCaseDeleteStatus(state, data) {
    state.caseDeleteStatus = data
  },

  setFileUploadURLForAttachments(state, data) {
    state.attachmentUploadURL = data
  },
  setFileUploadRefIdForAttachment(state, data) {
    state.attachmentFileId = data
  },
  setAttachmentUploadStatusUpdated(state, data) {
    state.attachmentUploadStatusUpdated = data
  },
  setAttachmentUploadStatus(state, data) {
    state.attachmentUploadStatus = data
  },
  setAttachmentDeleteStatus(state, data) {
    state.attachmentDeleteStatus = data
  },
  setAccessionCreateStatus(state, data) {
    state.accessionCreateStatus = data
  },
  setAccessionCreationValidationErrors(state, data) {
    state.accessionCreationValidationErrors = data
  },
  setAccessionCreationValidationErrorCode(state, data) {
    state.accessionCreationValidationErrorCode = data
  },

  setRestoreOrderStatus(state, data) {
    state.restoreOrderStatus = data
  },
  setRestoreOrderError(state, data) {
    state.restoreOrderError = data
  },
  setGlobalSearchOrders(state, data) {
    state.globalSearchOrders = data
  },

  setShowMedication(state, status) {
    state.showMedication = status
  },
  setDeleteOrderBillingInfoStatus(state, status) {
    state.deleteOrderBillingInfoStatus = status
  },
  setDownloadOrderDataStatus(state, status) {
    state.downloadOrderDataStatus = status
  },
  setCaseInformationValidationErrorsCode(state, data) {
    state.caseInformationValidationErrorsCode = data
  },
  setCaseUpdateInformationValidationErrorsCode(state, data) {
    state.caseUpdateInformationValidationErrorsCode = data
  },
  setRequisition(state, report) {
    state.report = report
  },
  setRequisitionDownloadStatus(state, report) {
    state.requisitionDownloadStatus = report
  },
  setMultipleBarcodeSources(state, data) {
    state.multipleBarcodeSources = data;
  },
  setMultipleBarcodeStatus(state, data) {
    state.multipleBarcodeStatus = data;
  },
  setUpdateOrderStatus(state, status) {
    state.updateOrderStatus = status;
  },
  setValidationOrders(state, data) {
    state.validationOrders = data
  },
  setUpdateValidationStatus(state, data) {
    state.updateValidationStatus = data
  },
  setBulkValidations(state, data) {
    state.bulkValidations = data
  },
  setScanOrderLogs(state, data) {
    state.scanOrderLogs = data
  },
  setGetOrderDetails(state, data) {
    state.getOrderDetails = data
  },
  setCreateAccessionDetails(state, data) {
    state.createAccessionDetails = data
  },
  setOrderCounts(state, data) {
    state.orderCounts = data
  },

  reset(state) {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}
const actions = {
  getOrders: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", null, {
        root: true,
      })
      let response = []
      if (params) {
        response = await axios.get("/orders", {
          params,
        })
      } else {
        response = await axios.get("/orders")
      }
      commit("setAllOrders", response.data)
    } catch (err) {
      commit("setAllOrderErrors", true)
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      })
    }
  },

  fetchRequisitionById: async ({ commit, dispatch }, params) => {
    try {
      let orderId = params.orderId
      delete params.orderId
      commit("setRequisition", null)
      commit("errors/clear", null, { root: true })
      const response = await axios.get(`orders/${orderId}/e-requisition`, {
        params,
      })

      commit("setRequisition", response.data)
    } catch (err) {
      commit("setRequisition", null)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },

  downloadRequisitionForm: async ({ commit, dispatch }, params) => {
    try {
      let order_id = params.orderId
      delete params.orderId
      commit("setRequisitionDownloadStatus", false)
      commit("errors/clear", null, { root: true })
      const response = await axios.get(
        "orders/" + order_id + "/e-requisition",
        {
          params,
        }
      )
      let data = response.data
      const linkSource = `data:application/pdf;base64,${data}`
      const donwloadLink = document.createElement("a")

      const fileName = "requisition.pdf"
      donwloadLink.href = linkSource
      donwloadLink.download = fileName
      donwloadLink.click()

      commit("setRequisitionDownloadStatus", true)
    } catch (err) {
      commit("setRequisitionDownloadStatus", null)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  createNewCase: async (context, params) => {
    try {
      context.commit(
        "orders/setCaseInformationValidationErrors",
        {},
        {
          root: true,
        }
      )
      context.commit(
        "orders/setCaseInformationValidationErrorsCode",
        {},
        {
          root: true,
        }
      )
      context.commit("orders/setCaseInformationSaveStatus", false, {
        root: true,
      })
      const response = await axios.post("/orders", params)
      context.commit("orders/setCaseInformation", response.data.data, {
        root: true,
      })
      context.commit("orders/setCaseInformationSaveStatus", true, {
        root: true,
      })
      if (
        params.case_types.indexOf("TOXICOLOGY") > -1 ||
        params.case_types.indexOf("PGX_TEST") > -1
      ) {
        context.commit("orders/setShowMedication", true, {
          root: true,
        })
      } else {
        context.commit("orders/setShowMedication", false, {
          root: true,
        })
      }
    } catch (error) {
      context.commit("orders/setCaseInformationSaveStatus", false, {
        root: true,
      })
      if (error.response) {
        context.commit(
          "orders/setCaseInformationValidationErrorsCode",
          error.response.data,
          { root: true }
        )
        let errorData = await context.dispatch(
          "errors/errorResponse",
          error.response,
          {
            root: true,
          }
        )
        context.commit("orders/setCaseInformationValidationErrors", errorData, {
          root: true,
        })
      }
    }
  },

  updateCase: async ({ commit, dispatch }, params) => {
    try {
      commit("setCaseInformationValidationErrors", {})
      commit("setCaseInformationSaveStatus", false)
      commit("setCaseUpdateInformationValidationErrorsCode", {})
      const id = params.order_id
      delete params.order_id
      const response = await axios.put(`/orders/${id}`, params)
      commit("setCaseInformation", response.data.data)
      commit("setCaseInformationSaveStatus", true)
      if (
        params.case_types.indexOf("TOXICOLOGY") > -1 ||
        params.case_types.indexOf("PGX_TEST") > -1
      ) {
        commit("setShowMedication", true)
      } else {
        commit("setShowMedication", false)
      }
    } catch (error) {
      commit("setCaseInformationSaveStatus", false)
      if (error.response) {
        let errorData = await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
        commit("setCaseInformationValidationErrors", errorData)
        commit(
          "setCaseUpdateInformationValidationErrorsCode",
          error.response.data
        )
      }
    }
  },
  downloadOrdersData: async ({ commit }, params) => {
    try {
      commit("setDownloadOrderDataStatus", false)
      const response = await axios.get("/orders/download-as-csv", {
        params,
      })
      commit("setDownloadOrderDataStatus", true)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      const currentDate = moment().tz("America/New_York");
      const fileName = currentDate.format('MM-DD-YYYY_hh-mm_A');
      link.setAttribute("download", `orders_${fileName}.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      commit("setDownloadOrderDataStatus", false)
      console.log(error)
    }
  },

  fetchCaseInformation: async ({ commit }, params) => {
    try {
      commit("setCaseInformation", {})
      const response = await axios.get("/orders/" + params.order_id)
      commit("setCaseInformation", response.data.data)
      if (
        response.data.data.case_types.indexOf("TOXICOLOGY") > -1 ||
        response.data.data.case_types.indexOf("PGX_TEST") > -1
      ) {
        commit("setShowMedication", true)
      } else {
        commit("setShowMedication", false)
      }
    } catch (error) {
      if (error && error.data && error.data.message) {
        commit("setCaseInformationError", error.data.message)
      } else {
        commit("setCaseInformationError", "Error while getting the data")
      }
    }
  },

  deleteCase: async ({ commit, dispatch }, params) => {
    try {
      let order_id = params.order_id
      delete params.order_id
      commit("setCaseDeleteStatus", false)
      await axios.delete("/orders/" + order_id, {
        data: params,
      })
      commit("setCaseDeleteStatus", true)
    } catch (error) {
      if (error && error.data && error.data.message) {
        commit("setCaseDeleteError", error.data.message)
      } else {
        commit("setCaseDeleteError", "Error while deleting case")
      }
    }
  },

  fetchDocumentUploadUrl: async ({ commit }, params) => {
    try {
      commit("setFileUploadURLForAttachments", null)
      commit("setFileUploadRefIdForAttachment", null)
      const response = await axios.post(
        "/orders/" + params.order_id + "/" + params.type,
        params
      )
      const data = response.data.data
      commit("setFileUploadURLForAttachments", data.target_url)
      commit("setFileUploadRefIdForAttachment", data.hash)
    } catch (error) {
      console.log(error)
    }
  },

  uploadDocumentToGeneratedUrl: async ({ commit }, params) => {
    try {
      commit("setAttachmentUploadStatus", false)
      let pAxios = PrivateAxios.create({})
      await pAxios.put(params.upload_url, params.file, {
        headers: {
          "Content-Type": params.file.type,
        },
      });
      commit("setAttachmentUploadStatus", true);
    } catch (error) {
      commit("setAttachmentUploadStatus", false)
    }
  },
  updateDocumentUploadStatus: async ({ commit }, params) => {
    try {
      commit("setAttachmentUploadStatusUpdated", false)
      await axios.patch(
        "/orders/" +
        params.order_id +
        "/" +
        params.type +
        "/" +
        params.hash +
        "/status"
      )
      commit("setAttachmentUploadStatusUpdated", true)
    } catch (error) {
      commit("setAttachmentUploadStatusUpdated", false)
    }
  },

  deleteAttachment: async ({ commit }, params) => {
    try {
      commit("setAttachmentDeleteStatus", false)
      await axios.delete(
        "/orders/" + params.order_id + "/attachments/" + params.id,
        {
          data: params,
        }
      )
      commit("setAttachmentDeleteStatus", true)
    } catch (error) {
      commit("setAttachmentDeleteStatus", false)
    }
  },

  createAccessionForOrder: async ({ commit, dispatch }, params) => {
    try {
      commit(
        "orders/setAccessionCreationValidationErrors",
        {},
        {
          root: true,
        }
      )
      commit(
        "orders/setAccessionCreationValidationErrorCode",
        {},
        {
          root: true,
        }
      )
      commit("orders/setAccessionCreateStatus", false, {
        root: true,
      })
      commit("orders/setCreateAccessionDetails", null, {
        root: true,
      })
      let response = await axios.post(
        "orders/" + params.order + "/create-accession-number",
        params
      )
      commit("orders/setAccessionCreateStatus", true, {
        root: true,
      })
      commit("orders/setCreateAccessionDetails", response.data.data, {
        root: true,
      })
    } catch (error) {
      commit("orders/setAccessionCreateStatus", false, {
        root: true,
      })
      commit("orders/setCreateAccessionDetails", null, {
        root: true,
      })
      if (error.response) {
        const errorData = await dispatch(
          "errors/errorResponse",
          error.response,
          {
            root: true,
          }
        )
        commit("setAccessionCreationValidationErrorCode", error.response.data)
        commit("setAccessionCreationValidationErrors", errorData)
      }
    }
  },

  restoreArchivedOrder: async ({ commit, dispatch }, params) => {
    try {
      commit("setRestoreOrderError", null)
      commit("setRestoreOrderStatus", false)
      let status = {
        status: params.status,
      }
      await axios.patch("/orders/" + params.order_id + "/restoreOrder", status)
      commit("setRestoreOrderStatus", true)
    } catch (err) {
      commit("setRestoreOrderStatus", false)
      if (err.response) {
        commit(
          "setRestoreOrderError",
          err.response.message || "Error at restore order"
        )
      } else {
        commit("setRestoreOrderError", "Error at restore order")
      }
    }
  },
  fetchGlobalSearchOrdersData: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], {
        root: true,
      })
      let response = []
      if (params) {
        response = await axios.get("/orders-search", {
          params,
        })
      } else {
        response = await axios.get("/orders-search")
      }
      commit("setGlobalSearchOrders", response.data)
    } catch (err) {
      commit("setAllOrderErrors", true)
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      })
    }
  },

  deleteBillingInfo: async ({ commit }, order_id) => {
    try {
      commit("setDeleteOrderBillingInfoStatus", false)
      await axios.delete(`orders/${order_id}/billing-information`)
      commit("setDeleteOrderBillingInfoStatus", true)
    } catch (err) {
      commit("setDeleteOrderBillingInfoStatus", false)
    }
  },
  generateMultipleBarcodes: async ({ commit }, params) => {
    try {
      commit("setMultipleBarcodeStatus", false);
      commit("setMultipleBarcodeSources", []);
      const response = await axios.post("/orders/generate-bar-codes", params);
      commit("setMultipleBarcodeStatus", true);
      commit("setMultipleBarcodeSources", response.data.data);
    } catch (error) {
      commit("setMultipleBarcodeStatus", false);
    }
  },
  fetchValidationOrders: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("/validation-orders", { params })

      commit("setValidationOrders", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  changeCaseValidationStatus: async ({ commit }, params) => {
    try {
      commit("setUpdateValidationStatus", false)
      await axios.patch(
        "/orders/" + params.id + "/validation-status/" + params.status,
        { validation_reason: params.validation_reason }
      )
      commit("setUpdateValidationStatus", true)
    } catch (error) {
      if (error.response) {
        console.log(error.response)
      }
    }
  },
  updateBulkValidations: async ({ commit, dispatch }, params) => {
    try {
      let status = params.status
      delete params.status
      commit("setBulkValidations", false)

      await axios.patch(`/orders/validation-status/${status}/bulk`, params)

      commit("setBulkValidations", true)
    } catch (error) {
      commit("setBulkValidations", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  resetGenerateMultipleBarcodes: async ({ commit }) => {
    commit("setMultipleBarcodeStatus", false);
    commit("setMultipleBarcodeSources", []);
  },
  fetchScanOrderLogs: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, { root: true })
      const response = await axios.get("/audit-logs/scan-order", { params })

      commit("setScanOrderLogs", response.data)
    } catch (err) {
      commit("setScanOrderLogs", [])
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  fetchScanOrderById: async ({ commit, dispatch }, params) => {
    try {
      let orderId = params.orderId
      delete params.orderId
      commit("setGetOrderDetails", null)
      commit("errors/clear", null, { root: true })
      const response = await axios.get(`orders/by-order-id/${orderId}`, {
        params,
      })

      commit("setGetOrderDetails", response.data.data)
    } catch (err) {
      commit("setGetOrderDetails", null)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  changeOrderStatus: async ({ commit }, params) => {
    try {
      commit("setUpdateOrderStatus", null);
      let response = await axios.patch(
        "/orders/" + params.id + "/status",
        params
      );
      commit("setUpdateOrderStatus", response.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  },
  fetchOrderStats: async ({ commit }) => {
    try {
      commit('setOrderCounts', {})

      let response = await axios.get('/pending-rejection-order/stats')
      commit('setOrderCounts', response.data.data)
    } catch (err) {
      console.log(err)
      commit('setOrderCounts', {})

    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
