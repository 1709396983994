import AuthMiddleware from "@/middleware/auth";

const userAccount = () =>
  import(
    /* webpackChunkName: "useraccounts" */ "@/views/userAccounts/UserAccount"
  );
const ActiveUserAccounts = () =>
  import(
    /* webpackChunkName: "useraccounts" */ "@/views/userAccounts/ActiveUserAccounts"
  );
const InActiveUserAccounts = () =>
  import(
    /* webpackChunkName: "useraccounts" */ "@/views/userAccounts/InActiveUserAccounts"
  );
const addUser = () =>
  import(/* webpackChunkName: "useraccounts" */ "@/views/userAccounts/AddUser");
const UserAccountsRouter = [
  //user accounts
  {
    path: "/user-accounts",
    name: "userAccounts",
    component: userAccount,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "USER ACCOUNTS",
    },
  },

  {
    path: "/user-accounts/active",
    name: "ActiveUserAccounts",
    component: ActiveUserAccounts,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "USER ACCOUNTS",
    },
  },
  {
    path: "/user-accounts/in-active",
    name: "InActiveUserAccounts",
    component: InActiveUserAccounts,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "USER ACCOUNTS",
    },
  },
  {
    path: "/user-accounts/add",
    name: "addUser",
    component: addUser,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "USER ACCOUNT",
    },
  },
  {
    path: "/user-accounts/:user_id/update",
    name: "EditUserAccount",
    component: addUser,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "USER ACCOUNT",
    },
  },
];
export { UserAccountsRouter };
