import AuthMiddleware from "@/middleware/auth";
// Labs crud
const Labs = () =>
  import(/* webpackChunkName: "topdog" */ "@/views/topdog/labs/AllLabs");
const AddLab = () =>
  import(/* webpackChunkName: "topdog" */ "@/views/topdog/labs/AddLab");
const ViewLab = () =>
  import(/* webpackChunkName: "topdog" */ "@/views/topdog/labs/ViewLab");

// Labs panel
const TestPanel = () =>
  import(/* webpackChunkName: "topdog" */ "@/views/topdog/labs/TestPanel");

// Lab User Accounts
const LabUserAccounts = () =>
  import(
    /* webpackChunkName: "topdog" */ "@/views/topdog/labs/userAccounts/users/LabUserAccounts"
  );
const AddLabUserAccount = () =>
  import(
    /* webpackChunkName: "topdog" */ "@/views/topdog/labs/userAccounts/users/AddLabUserAccount"
  );

const topdogRouters = [
  {
    path: "/labs",
    name: "AllLabs",
    component: Labs,
    meta: {
      title: "ALL LABS",
    },
    beforeEnter: AuthMiddleware.topdog,
  },
  {
    path: "/add-lab",
    name: "AddLab",
    component: AddLab,
    meta: {
      title: "ADD LAB",
    },
    beforeEnter: AuthMiddleware.topdog,
  },
  {
    path: "/edit-lab/:lab_id",
    name: "EditLab",
    component: AddLab,
    meta: {
      title: "EDIT LAB",
    },
    beforeEnter: AuthMiddleware.topdog,
  },
  {
    path: "/view-lab/:lab_id",
    name: "ViewLab",
    component: ViewLab,
    meta: {
      title: "VIEW LAB",
    },
    beforeEnter: AuthMiddleware.topdog,
  },
  {
    path: "/test-panel/:lab_id",
    name: "TestPanel",
    component: TestPanel,

    beforeEnter: AuthMiddleware.topdog,
  },
  {
    path: "/lab-users/:lab_id",
    name: "LabUsers",
    component: LabUserAccounts,

    beforeEnter: AuthMiddleware.topdog,
  },
  {
    path: "/lab-users/:lab_id/add",
    name: "AddLabUsers",
    component: AddLabUserAccount,
    meta: {
      title: "ADD LAB USER ",
    },
    beforeEnter: AuthMiddleware.topdog,
  },
  {
    path: "/lab-users/:lab_id/edit/:user_id",
    name: "EditLabUser",
    component: AddLabUserAccount,
    meta: {
      title: "EDIT LAB USER ",
    },
    beforeEnter: AuthMiddleware.topdog,
  },
];

export { topdogRouters };
